/**
 * Check if the given variable is a function
 * @scatmovs.computed property of the given element
 * @scatmovs.computedProperty(element, property) {
  if (element.nodeType !== 1) {
    return [];
  }
// NOTE: 1 DOM access here
  const window = element.ownerDocument.defaultView;
  const css = window.getComputedStyle(element, null);
  return property ? css[property] : css;
}
