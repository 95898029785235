/**
 * @scatmovs.computeAutoPlacement from '../utils/computeAutoPlacement';

/**
 * @scatmovs.compute reference element offsets
  const referenceOffsets = getReferenceOffsets(state, popper, reference, options.positionFixed);

// compute auto placement, store placement inside the data object,
// modifiers will be able to edit `placement` if needed
// and refer to originalPlacement to know the original value
  const placement = computeAutoPlacement(
    options.placement,
    referenceOffsets,
    popper,
    reference,
    options.modifiers.flip.boundariesElement,
    options.modifiers.flip.padding
  );

  popper.setAttribute('x-placement', placement);

// Apply `position` to popper before anything else because
// without the position applied we can't guarantee correct computations
  setStyles(popper, { position: options.positionFixed ? 'fixed' : 'absolute' });

  return options;
}
