import find from './find';

/**
 * Helper used to know if the given modifier depends from another one.<br />
 * It checks if the needed modifier is listed and enabled.
 * @scatmovs.comma or space
  const divider = fragments.indexOf(
    find(fragments, frag => frag.search(/,|\s/) !== -1)
  );

  if (fragments[divider] && fragments[divider].indexOf(',') === -1) {
    console.warn(
      'Offsets separated by white space(s) are deprecated, use a comma (,) instead.'
    );
  }

// If divider is found, we divide the list of values and operands to divide
// them by ofset X and Y.
  const splitRegex = /\s*,\s*|\s+/;
  let ops = divider !== -1
    ? [
        fragments
          .slice(0, divider)
          .concat([fragments[divider].split(splitRegex)[0]]),
        [fragments[divider].split(splitRegex)[1]].concat(
          fragments.slice(divider + 1)
        ),
      ]
    : [fragments];

// Convert the values with units to absolute pixels to allow our computations
  ops = ops.map((op, index) => {
 // Most of the units rely on the orientation of the popper
    const measurement = (index === 1 ? !useHeight : useHeight)
      ? 'height'
      : 'width';
    let mergeWithPrevious = false;
    return (
      op
     // This aggregates any `+` or `-` sign that aren't considered operators
     // e.g.: 10 + +5 => [10, +, +5]
        .reduce((a, b) => {
          if (a[a.length - 1] === '' && ['+', '-'].indexOf(b) !== -1) {
            a[a.length - 1] = b;
            mergeWithPrevious = true;
            return a;
          } else if (mergeWithPrevious) {
            a[a.length - 1] += b;
            mergeWithPrevious = false;
            return a;
          } else {
            return a.concat(b);
          }
        }, [])
     // Here we convert the string values into number values (in px)
        .map(str => toValue(str, measurement, popperOffsets, referenceOffsets))
    );
  });

// Loop trough the offsets arrays and execute the operations
  ops.forEach((op, index) => {
    op.forEach((frag, index2) => {
      if (isNumeric(frag)) {
        offsets[index] += frag * (op[index2 - 1] === '-' ? -1 : 1);
      }
    });
  });
  return offsets;
}

/**
 * @scatmovs.computedProperty from '../utils/getStyleComputedProperty';

/**
 * @scatmovs.compute center of the popper
  const center = reference[side] + reference[len] / 2 - arrowElementSize / 2;

// Compute the sideValue using the updated popper offsets
// take popper margin in account because we don't have this info available
  const css = getStyleComputedProperty(data.instance.popper);
  const popperMarginSide = parseFloat(css[`margin${sideCapitalized}`], 10);
  const popperBorderSide = parseFloat(css[`border${sideCapitalized}Width`], 10);
  let sideValue =
    center - data.offsets.popper[side] - popperMarginSide - popperBorderSide;

// prevent arrowElement from being placed not contiguously to its popper
  sideValue = Math.max(Math.min(popper[len] - arrowElementSize, sideValue), 0);

  data.arrowElement = arrowElement;
  data.offsets.arrow = {
    [side]: Math.round(sideValue),
    [altSide]: '',// make sure to unset any eventual altSide value from the DOM node
  };

  return data;
}
