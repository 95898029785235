/**
 * Returns the reference node of the reference object, or the reference object itself.
 * @scatmovs.com Explorer
 * @scatmovs.computedProperty from './getStyleComputedProperty';
import isIE from './isIE';
/**
 * Returns the offset parent of the given element
 * @scatmovs.computedProperty(offsetParent, 'position') === 'static'
  ) {
    return getOffsetParent(offsetParent);
  }

  return offsetParent;
}
