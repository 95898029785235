import getStyleComputedProperty from './getStyleComputedProperty';
import isIE from './isIE';
/**
 * Finds the first parent of an element that has a transformed property defined
 * @scatmovs.computedProperty(el, 'transform') === 'none') {
    el = el.parentElement;
  }
  return el || document.documentElement;

}
